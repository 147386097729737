import React from 'react'
import Burger from './burger/Burger'
import "./Navbar.css"
import profile from "../../media/profile-cartoon.png"

export default function Navbar({ isScrolling }) {
    return (
        <div className={`navbar ${isScrolling > 120? "scrolling" : null }`}>
            <a href="/">
                <img className="profile-picture" src={profile} alt="profile"/>
            </a>
            <div className="menu-full">
                <div className="menu-full-item">
                    <p className="link-number-full">01. </p>
                    <a href="#projects">Projects</a>
                </div>
                <div className="menu-full-item">
                    <p className="link-number-full">02. </p>
                    <a href="#about">About</a>
                </div>
                <div className="menu-full-item">
                    <p className="link-number-full">03. </p>
                    <a href="#experience">Experience</a>
                </div>
                <div className="menu-full-item">
                    <p className="link-number-full">04. </p>
                    <a href="#contact">Contact</a>
                </div>
            </div>
            <Burger/>
        </div>
    )
}
