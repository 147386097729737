import React from 'react'
import "./BurgerLink.css"

export default function burgerLink(props) {
    const {number, link, text, closeSideBar} = props;
    return (
        <div className="burgerLink">
            <p className="link-number">{number}</p>
            <a href={link} onClick={() => closeSideBar()}>{text}</a>
        </div>
    )
}
