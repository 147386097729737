import React from 'react'
import "./Map.css"

export default function Map() {
    return (
        <div className="map">
            <div className="address">
                <p className="address-name">Guillermo Martín,</p>
                <p className="address-line1">118 Francesc Layret st.</p>
                <p className="address-line2">No. 4-3, Badalona</p>
                <br/>
                <p className="address-email"><span className="important">@</span>: guillem.mart7@gmail.com</p>
            </div>
        </div>
    )
}
