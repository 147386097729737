import React from 'react'
import Title from '../title/Title'
import "./About.css"
import profile from "../../media/profile.jpeg"
import javascript from "../../media/technologies/javascript.png"
import html from "../../media/technologies/html.png"
import css from "../../media/technologies/css.png"
import nodejs from "../../media/technologies/nodejs.png"
import react from "../../media/technologies/react.png"
import tailwind from "../../media/technologies/tailwind.png"
import mongodb from "../../media/technologies/mongodb.png"
import VSCode from "../../media/technologies/VSCode.png"
import postman from "../../media/technologies/postman.png"
import typescript from "../../media/technologies/typescript.png"
import git from "../../media/technologies/git.png"
import github from "../../media/technologies/github.png"

export default function About() {
    return (
        <div className="about">
            <div id="about"></div>
            <Title number="02." title="About Me"/>
            <div className="about-content">
                <div className="about-text">
                    <p className="text">Hello! My name is Guillermo and I enjoy creating things that live on the internet. My interest in web development started back in 2019 when I came up with an idea for a <span className="important">voting movie app</span> — I would have never imagined that it would become such a moving passion for me!</p>
                    <p className="text">Fast forward to today, I've had the privilege of working at a software consulting agency, a design-led product corporation, and a private programming academy. My main focus these days is building competitive products that our customers love at <span className="important">JANÉ. S.A</span>.</p>
                    <p className="text">I have also been recently hired at <span className="important">Ironhack Barcelona</span> as teacher assistant, to help new students find their way to become new FullStack Web Developers.</p>
                    <p className="text">Here are a few technologies and technologies I have been working with, and some I am currently <span className="important">learning</span> about:</p>
                    <ul>
                        <li className="list-item"> <img className="technology-logo" src={javascript} alt="javascript"/>JavaScript (ES6+)</li>
                        <li className="list-item"> <img className="technology-logo" src={react} alt="react"/>React</li>
                        <li className="list-item"> <img className="technology-logo" src={html} alt="html"/>HTML</li>
                        <li className="list-item"> <img className="technology-logo" src={nodejs} alt="node.js"/>Node.js</li>
                        <li className="list-item"> <img className="technology-logo" src={css} alt="css"/>CSS</li>
                        <li className="list-item"> <img className="technology-logo" src={tailwind} alt="tailwind"/>Tailwind</li>
                        <li className="list-item"> <img className="technology-logo" src={typescript} alt="typescript"/>Typescript</li>
                        <li className="list-item"> <img className="technology-logo" src={mongodb} alt="mongodb"/>mongoDB</li>
                        <li className="list-item"> <img className="technology-logo" src={VSCode} alt="VSCode"/>VSCode</li>
                        <li className="list-item"> <img className="technology-logo" src={postman} alt="postman"/>Postman</li>
                        <li className="list-item"> <img className="technology-logo" src={git} alt="git"/>Git</li>
                        <li className="list-item"> <img className="technology-logo" src={github} alt="github"/>GitHub</li>
                    </ul>
                </div>
                <div className="about-picture">
                    <img className="main-profile-picture wrapper" src={profile} alt="profile"/>
                </div>
            </div>
        </div>
    )
}
