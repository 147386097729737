import React, { useState } from 'react'
import "./Burger.css"
import {slide as Menu} from "react-burger-menu"
import resume from "../../../media/resume.pdf"
import BurgerLink from "./burgerLink/BurgerLink"

export default function Burger() {
    const [isOpen, setOpen] = useState(false);
    const closeSideBar = () => {
        setOpen(false);
    };
    const handleIsOpen = () => {
        setOpen(!isOpen);
    }
    return (
        <Menu noOverlay burgerButtonClassName={"burger-btn"} isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen} right id={"sidebar"} className="Menu">
            <BurgerLink number="01." link="#projects" text="Projects" closeSideBar={closeSideBar}/>
            <BurgerLink number="02." link="#about" text="About" closeSideBar={closeSideBar}/>
            <BurgerLink number="03." link="#experience" text="Experience" closeSideBar={closeSideBar}/>
            <BurgerLink number="04." link="#contact" text="Contact" closeSideBar={closeSideBar}/>
            <a className="resume-link" href={resume} download>Resume</a>
        </Menu>
    )
}
