import React from 'react'
import "./Footer.css"

export default function Footer() {
    return (
        <div className="footer">
            <div className="personal-links">
                <a href="https://github.com/Portela44"><img className="project-icon" src="https://icon-library.com/images/github-icon-white/github-icon-white-6.jpg" alt="github"/></a>
                <a href="https://www.instagram.com/portela_44/?hl=es"><img className="project-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Instagram_logo.png/1200px-Instagram_logo.png" alt="instagram"/></a>
                <a href="https://www.linkedin.com/in/gmartinportela/"><img className="project-icon" src="https://toppng.com/public/uploads/thumbnail/linkedin-white-icon-social-media-icon-png-and-vector-1a-logo-11563638575rmvclwzay3.png" alt="linkedin"/></a>
            </div>
            <p className="credits">Designed by <a className="important" href="https://brittanychiang.com/#jobs">Brittany Chiang</a>, built and fueled by Guillermo Martín.</p>
        </div>
    )
}