import React from 'react'
import "./ProjectFull.css"

export default function ProjectFull(props) {
    const {picture, title, body, technologies, github, deploy} = props
    return (
        <div className="projectFullWrapper">
            <div className={`projectFull bg ${picture}`}></div>
            <div className="text-section">
                <h5 className="featured-project">Featured Project</h5>
                <h3 className="project-name">{title}</h3>
                    {body}
                <ul className="technology-list">
                        {technologies && technologies.map(technology => <li key={technology}>{technology}</li>)}
                </ul>
                <div className="project-links">
                        <a href={github}><img className="project-icon" src="https://icon-library.com/images/github-icon-white/github-icon-white-6.jpg" alt="github"/></a>
                        <a href={deploy} className="deployed-link">Check it out!</a>
                </div>
            </div>
        </div>
    )
}