import React, { Component } from 'react'
import "./ToggleJobs.css"

export class ToggleJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabType: "job-1",
        }
    }
    toggleMe = (value) => {
        this.setState({
            tabType: value,
        })
    }
    render() {
        return(
            <div className="toggleJobs">
                <div className="company-btn-list">
                    <button className={`btn job-1 ${this.state.tabType==="job-1" ? "active" : ""}`} onClick={() => this.toggleMe("job-1")}>Ironhack</button>
                    <button className={`btn job-2 ${this.state.tabType==="job-2" ? "active" : ""}`} onClick={() => this.toggleMe("job-2")}>JANÉ S.A.</button>
                    <button className={`btn job-3 ${this.state.tabType==="job-3" ? "active" : ""}`} onClick={() => this.toggleMe("job-3")}>Construsoft</button>
                </div>
                <div className="company-info">
                    {this.state.tabType==="job-1" && <div className="unit-company-info">
                        <h3 className="job-title">Web Development Bootcamp Teaching Asssistant <span className="company-name"><a href="https://www.ironhack.com/es">@ Ironhack</a></span></h3>
                        <p className="job-time">Oct 2022 - Present (Part-Time)</p>
                        <ul>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Mentoring students with different backgrounds to become Web Developers alongside the lead instructor.
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Reviewing assignments and individual / pair programming projects submitted by students.
                            </li>
                        </ul>
                    </div>}
                    {this.state.tabType==="job-2" && <div className="unit-company-info">
                        <h3 className="job-title">Product Manager <span className="company-name"><a href="https://janeworld.com/">@ JANÉ S.A.</a></span></h3>
                        <p className="job-time">Feb 2018 - Present</p>
                        <ul>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Continuous build-up, analysis and maintenance of a product catalogue with more than 150 references divided in 6 sub-families. 
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Data Analysis and dashboarding for decision making. 
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Adaptation of all bussiness opportunities to an expanding multi-channel selling environment (B2B, ecommerce, Amazon).
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Study and implementation of new agile and project management tools to improve internal procedures and establish better synergies between all departments involved in Product development.
                            </li>
                        </ul>
                    </div>}
                    {this.state.tabType==="job-3" && <div className="unit-company-info">
                    <h3 className="job-title">BIM Consultant <span className="company-name"><a href="https://www.construsoft.es/es">@ Construsoft</a></span></h3>
                        <p className="job-time">Feb 2015 - Jan 2018</p>
                        <ul>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                BIM consultant in construction engineering projects, specialized in software interconection and fine-tuning for a competitivity improvement. 
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Content creator and set-up for the new company online course platform, collaborating in three different and interconnected engineering softwares.
                            </li>
                            <li className="cursor">▹</li>
                            <li className="job-experience">
                                Active collaborator in software development processes, including feature prioritization, time management, product debuging and customer satisfaction tests, with teams in Belgium and Czech Republic.
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        )
    }
}
export default ToggleJobs;
