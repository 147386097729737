import React from 'react'
import "./Cover.css"
import resume from "../../media/resume.pdf"

export default function Cover() {
  return (
    <div className="cover">
        <p className="hi">Hi, my name is</p>
        <h1 className="my-name">Guillermo Martín.</h1>
        <h1 className="whatIDo">I build things for the web.</h1>
        <article className="text intro">I'm a FullStack Developer based in Barcelona with a background in industrial engineering and Product Management.
            Proactive and motivation driven, I keep learning new technologies every day to develop personal projects.
        </article>
        <div>
          <a className="main-link" href={resume} download>Download my resume!</a>
        </div>
    </div>
  )
}
