import React from 'react'
import "./Title.css"

export default function title(props) {
    const {number, title} = props;
    return (
        <div className="title">
            <h2 className="title-name"><span className="title-number">{number}</span> {title}</h2>
        </div>
    )
}
