import React, {useState, useEffect} from "react"
import './App.css';
import Navbar from './components/navbar/Navbar';
import Cover from './components/cover/Cover';
import About from "./components/about/About";
import Projects from './components/projects/Projects';
import Experience from "./components/experience/Experience";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

function App() {
  const [scrollHeight, setScrollHeight] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollHeight(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [scrollHeight]);
  return (
    <div className="App">
      <Navbar isScrolling={scrollHeight} />
      <Cover />
      <Projects />
      <About />
      <Experience />
      <Contact />
      <Footer/>
    </div>
  );
}

export default App;
