import React, {useState} from 'react'
import "./Contact.css"
import Title from '../title/Title'
import Map from './map/Map';

export default function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    return (
        <div className="contact">
            <div id="contact"></div>
            <Title number="04." title="Get In Touch"/>
            <p className="text reach-out">I am currently looking for a challenging opportunity to keep learning new technologies, or consolidate the ones I already started with. Don't hesitate to reach out if you have any comment, offer or question!</p>
            <div className="contact-responsive">
                <form action="https://formsubmit.co/34c4b3935a8df27068c86a747b4851d1" method="POST" className="contact-form">
                    <div>
                        <input type="text" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required></input>
                        <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required></input>
                    </div>
                    <input type="text" name="_subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)}></input>
                    <textarea type="text" name="message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    <input type="hidden" name="_next" value="http://localhost:3000/"></input>
                    <button className="send-message-btn" type="submit">Send message!</button>
                </form>
                <Map/>
            </div>
        </div>
    )
}
