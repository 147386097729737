import React from 'react'
import "./Projects.css"
import Title from '../title/Title'
import ProjectCard from './projectCard/ProjectCard'
import ProjectFull from './projectFull/ProjectFull'

export default function Projects() {
    return (
        <div className="projects">
            <div id="projects"></div>
            <Title className="project-title" number="01." title="Some Things I've Built"/>
            <div className="projectCardSection">
                <ProjectCard picture="hollowKnight-bg"  title="Hollow Knight - Canvas Edition" body={<p className="project-body">This Canvas game tries to reproduce the ambiance, the feel and the gameplay of the best-selling 2D platform game <span className="important">Hollow Knight</span>, from Team Cherry.</p>} technologies={["HTML","CSS","Javascript"]} github="https://github.com/Portela44/hollow-knight-canvas-edition" deploy="https://portela44.github.io/hollow-knight-canvas-edition/"/>
                <ProjectCard picture="MovieMatch-bg"  title="MovieMatch" body={<p className="project-body">"There is still room for improvement when we talk about how users get their <span className="important">recommendations</span> to watch online content". That was the seed that led to this first prototype: It allows users to vote any movie, and get recommendations based on these, and on their personal genre taste!</p>} technologies={["Nodejs","Express","Mongoose", "Handlebars"]} github="https://github.com/Portela44/MovieMatch" deploy="https://movie-match.fly.dev/"/>
                <ProjectCard picture="MovieFetch-bg"  title="MovieFetch" body={<p className="project-body">In this natural evolution of the previous <span className="important">MovieMatch</span> app, using now React user experience is improved with a more complete personal <span className="important">profile</span>, <span className="important">reviews</span>, a new <span className="important">like</span> system and a personal <span className="important">watch-later</span> list. User interface is brought to the next level, try it on 'Iphone 12 Pro' configuration!</p>} technologies={["React","Nodejs","MongoDB","Tailwind"]} github="https://github.com/Portela44/movieAppFrontend" deploy="https://moviefetchapp.netlify.app/"/>
                <ProjectCard picture="chalk-to-do-list-bg"  title="Chalk To-Do List" body={<p className="project-body">In this to-do list web application, any user can create <span className="important">new tasks</span>, sort the task-list by <span className="important">priority</span>, and of course, modify, delete and set-as-complete any task of the list.</p>} technologies={["HTML","CSS","Javascript"]} github="https://github.com/Portela44/ultimate-todo-app" deploy="https://luxury-kataifi-bf5191.netlify.app/"/>
            </div>
            <div className="projectFullSection">
                <ProjectFull picture="hollowKnight-bg" title="Hollow Knight - Canvas Edition" body={<p className="project-body">This Canvas game tries to reproduce the ambiance, the feel and the gameplay of the best-selling 2D platform game <span className="important">Hollow Knight</span>, from Team Cherry.</p>} technologies={["HTML","CSS","Javascript"]} github="https://github.com/Portela44/hollow-knight-canvas-edition" deploy="https://portela44.github.io/hollow-knight-canvas-edition/"/>
                <ProjectFull picture="MovieMatch-bg" title="MovieMatch" body={<p className="project-body">"There is still room for improvement when we talk about how users get their <span className="important">recommendations</span> to watch online content". That was the seed that led to this first prototype: It allows users to vote any movie, and get recommendations based on these, and on their personal genre taste!</p>} technologies={["Nodejs","Express","Mongoose", "Handlebars"]} github="https://github.com/Portela44/MovieMatch" deploy="https://recommend---me.herokuapp.com/"/>
                <ProjectFull picture="MovieFetch-bg" title="MovieFetch" body={<p className="project-body">In this natural evolution of the previous <span className="important">MovieMatch</span> app, using now React user experience is improved with a more complete personal <span className="important">profile</span>, <span className="important">reviews</span>, a new <span className="important">like</span> system and a personal <span className="important">watch-later</span> list. User interface is brought to the next level, try it on 'Iphone 12 Pro' configuration!</p>} technologies={["React","Nodejs","MongoDB","Tailwind"]} github="https://github.com/Portela44/movieAppFrontend" deploy="https://moviefetchapp.netlify.app/"/>
                <ProjectFull picture="chalk-to-do-list-bg" title="Chalk To-Do List" body={<p className="project-body">In this to-do list web application, any user can create <span className="important">new tasks</span>, sort the task-list by <span className="important">priority</span>, and of course, modify, delete and set-as-complete any task of the list. All with a fancy chalk-board appearance.</p>} technologies={["HTML","CSS","Javascript"]} github="https://github.com/Portela44/ultimate-todo-app" deploy="https://luxury-kataifi-bf5191.netlify.app/"/>
            </div>
        </div>
    )
}
