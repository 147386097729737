import React from 'react'
import "./Experience.css"
import Title from '../title/Title'
import ToggleJobs from './ToggleJobs/ToggleJobs'

export default function Experience() {
    return (
        <div className="experience">
            <div id="experience"></div>
            <Title number="03." title="Where I've Worked"/>
            <ToggleJobs/>
        </div>
    )
}
